import React, { memo, Fragment } from 'react'
import { Form } from 'react-bootstrap'
const FilterOptions = memo(({ uniqueName, id, productName, productChecked, onChange }) => {
    return (
        <Fragment>
            <Form.Group controlId={`formBasicCheckbox-${id}`}>
                <Form.Check
                    type="checkbox"
                    label={productName}
                    id={`${uniqueName}-${id}`}
                    checked={productChecked}
                    onChange={onChange}
                />
            </Form.Group>
        </Fragment>
    )
})
FilterOptions.displayName = 'FilterOptions'
export default FilterOptions
