import React, { useCallback } from 'react'
import { Badge } from 'react-bootstrap'

const Sentiment = ({ feed, style }) => {
    const getSentiment = useCallback(() => {
        return {
            badge: feed.sentiment === 1
                ? 'success'
                : (feed.sentiment === -1 ? 'danger' : 'warning'),
            text: feed.sentiment === 1
                ? 'positive'
                : (feed.sentiment === -1 ? 'negative' : 'neutral')
        }
    }, [feed])

    return (
        <span style={style}>
            {feed.sentiment !== null && (
                <Badge bg={getSentiment().badge}>{getSentiment().text}</Badge>
            )}
        </span>
    )
}

export default Sentiment