import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import Card from '../common/Card'
import { useChart } from '../../utils/chart-hook'
import _ from 'lodash'

const AudienceBySegmentChart = ({ name, data, keywords }) => {
    if (data.length === 0) {
        data = [{ value: '', audience: 0 }]
    }

    const { options } = useChart('pie', data, ['audienceThisWeek', 'audienceThisMonth', 'audienceThisYear'])

    const customisedOptions = _.cloneDeep(options)
    customisedOptions.theme.monochrome.color = '#08B1BA'

    const usedKeywords = _.filter(data, (keyword) => keywords.includes(keyword.value))

    const labels = _.map(usedKeywords, (keyword) => keyword.value)
    const series = _.map(usedKeywords, (keyword) => keyword.audience)

    customisedOptions['labels'] = labels

    return (
        <Col md={12} xl={5}>
            <Card data-aos="fade-up" data-aos-delay="1000">
                <div className="flex-wrap card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">{name}</h4>
                    </div>
                </div>
                <Card.Body>
                    <Chart className="d-activity" options={customisedOptions} series={series} type="pie" height="300" />
                </Card.Body>
            </Card>
        </Col>
    )
}

export default AudienceBySegmentChart