import { useContext } from 'react'
import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import { AuthContext } from '../../context/auth-context'
import Card from '../common/Card'

const ConversionRateBarChart = ({ name, data, creatives }) => {
    const { user } = useContext(AuthContext)
    const conversion = JSON.parse(data.conversionPerAds)
    const labels = conversion
        ? Object.keys(conversion).map((item) => creatives.find((creative) => creative.id === item).title)
        : creatives.map((creative) => creative.title)

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            }
        },
        colors: ['#427EEB'],
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: process.env.REACT_APP_DEMO_USER_ID.includes(user.id)
                ? ['Landing Page 1', 'Landing Page 2', 'Landing Page 3', 'Landing Page 4', 'Landing Page 5']
                : labels
        },
        grid: {
            show: false
        },
    }

    const series = [{
        name: 'Conversion Rate (%)',
        data: process.env.REACT_APP_DEMO_USER_ID.includes(user.id)
            ? [75, 85, 78, 72, 84]
            : (conversion ? Object.values(conversion).map((item) => item.toFixed(1)) : new Array(creatives.length).fill(0))
    }]

    return (
        <Col md={12} xl={7}>
            <Card data-aos="fade-up" data-aos-delay="1000">
                <div className="flex-wrap card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">{name}</h4>
                    </div>
                </div>
                <Card.Body>
                    <Chart className="d-activity" options={options} series={series} type="bar" height="300" />
                </Card.Body>
            </Card>
        </Col>
    )
}

export default ConversionRateBarChart