import { useState } from 'react'
import validator from 'validator'

export const useForm = (submitCallback, initialState = {}) => {
    const [values, setValue] = useState(initialState)
    const [validated, setValidated] = useState(false);

    const onChange = (event) => {
        setValue({ ...values, [event.target.name]: event.target.value })
    }

    const validateForm = (event) => {
        const form = event.target.form

        if (form.checkValidity()) {
            submitCallback()
        }

        setValidated(true);
    }


    const onSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()

        validateForm(event)
    }

    const validateEmail = (event) => {
        if (validator.isEmail(event.target.value)) {
            event.target.setCustomValidity('')
        } else {
            event.target.setCustomValidity('Please enter a valid Email')
        }
    }

    return {
        onChange,
        onSubmit,
        values,
        validated,
        validateEmail
    }
}