import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { showNotification } from '../../utils/notifications'

const Notification = () => {
    const location = useLocation()

    useEffect(() => {
        if (location.state?.notification) {
            showNotification(location.state.notification.message, location.state.notification.status)
            location.state.notification = ''
            window.history.replaceState({}, document.title)
        }
    }, [location.state])

    return (
        <ToastContainer />
    )
}

export default Notification