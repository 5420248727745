import React, { Fragment, useState } from 'react'
import Comment from './Comment'

const Comments = ({ comments, platform }) => {
    const [show, setShow] = useState(comments.slice(0, process.env.REACT_APP_COMMENTS_SHOW_BY_DEFAULT))
    const [shown, setShown] = useState(false)

    const toggle = () => {
        if (shown) {
            setShow(comments.slice(0, process.env.REACT_APP_COMMENTS_SHOW_BY_DEFAULT))
            setShown(false)
        } else {
            setShow(comments)
            setShown(true)
        }
    }

    return (
        <div className="comment-area pt-3" style={{ marginBottom: "-20px"}}>
            <div className="text-body d-flex align-items-center gap-2">
                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4"
                          d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z"
                          fill="currentColor"></path>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M11.9807 13.2901C11.2707 13.2801 10.7007 12.7101 10.7007 12.0001C10.7007 11.3001 11.2807 10.7201 11.9807 10.7301C12.6907 10.7301 13.2607 11.3001 13.2607 12.0101C13.2607 12.7101 12.6907 13.2901 11.9807 13.2901ZM7.37033 13.2901C6.67033 13.2901 6.09033 12.7101 6.09033 12.0101C6.09033 11.3001 6.66033 10.7301 7.37033 10.7301C8.08033 10.7301 8.65033 11.3001 8.65033 12.0101C8.65033 12.7101 8.08033 13.2801 7.37033 13.2901ZM15.3105 12.0101C15.3105 12.7101 15.8805 13.2901 16.5905 13.2901C17.3005 13.2901 17.8705 12.7101 17.8705 12.0101C17.8705 11.3001 17.3005 10.7301 16.5905 10.7301C15.8805 10.7301 15.3105 11.3001 15.3105 12.0101Z"
                          fill="currentColor"></path>
                </svg>
                <span className=" d-none d-sm-block">{comments.length} Comments</span>
                {comments.length > process.env.REACT_APP_COMMENTS_SHOW_BY_DEFAULT && (
                    <Fragment>
                        {shown ? (
                            <div style={{cursor: 'pointer'}} onClick={() => toggle()}>
                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" height="32">
                                    <path opacity="0.4" d="M2 12C2 6.485 6.486 2 12 2C17.514 2 22 6.485 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12Z" fill="currentColor"></path>
                                    <path d="M7.77942 13.4425C7.77942 13.2515 7.85242 13.0595 7.99842 12.9135L11.4684 9.42652C11.6094 9.28552 11.8004 9.20652 12.0004 9.20652C12.1994 9.20652 12.3904 9.28552 12.5314 9.42652L16.0034 12.9135C16.2954 13.2065 16.2954 13.6805 16.0014 13.9735C15.7074 14.2655 15.2324 14.2645 14.9404 13.9715L12.0004 11.0185L9.06042 13.9715C8.76842 14.2645 8.29442 14.2655 8.00042 13.9735C7.85242 13.8275 7.77942 13.6345 7.77942 13.4425Z" fill="currentColor"></path>
                                </svg>
                            </div>
                        ) : (
                            <div style={{cursor: 'pointer'}} onClick={() => toggle()}>
                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                     className="icon-32" height="20">
                                    <path opacity="0.4"
                                          d="M22 12C22 17.515 17.514 22 12 22C6.486 22 2 17.515 2 12C2 6.486 6.486 2 12 2C17.514 2 22 6.486 22 12Z"
                                          fill="currentColor"></path>
                                    <path
                                        d="M16.2211 10.5575C16.2211 10.7485 16.1481 10.9405 16.0021 11.0865L12.5321 14.5735C12.3911 14.7145 12.2001 14.7935 12.0001 14.7935C11.8011 14.7935 11.6101 14.7145 11.4691 14.5735L7.99707 11.0865C7.70507 10.7935 7.70507 10.3195 7.99907 10.0265C8.29307 9.73448 8.76807 9.73548 9.06007 10.0285L12.0001 12.9815L14.9401 10.0285C15.2321 9.73548 15.7061 9.73448 16.0001 10.0265C16.1481 10.1725 16.2211 10.3655 16.2211 10.5575Z"
                                        fill="currentColor"></path>
                                </svg>
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
            <ul className="list-inline mt-4">
                {show.map((comment) => (
                    <li className="mb-4" key={comment.id}>
                        <Comment
                            comment={comment}
                            platform={platform}
                        />
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Comments