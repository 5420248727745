import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import App from './App';
import reportWebVitals from './reportWebVitals'
import client from './utils/apollo-client'
import { ApolloProvider } from '@apollo/client'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { AuthProvider } from './context/auth-context'
import { SettingsProvider } from './context/settings-context'

Sentry.init({
    dsn: 'https://e876a74c4fda6d467b916a15d6e8111b@o4507844248993792.ingest.us.sentry.io/4507845220302848',
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root'))

TimeAgo.addDefaultLocale(en)

root.render(
    <SettingsProvider>
        <AuthProvider>
            <ApolloProvider client={client}>
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </ApolloProvider>
        </AuthProvider>
    </SettingsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
