import { gql } from '@apollo/client'

export const CORE_FEED_FIELDS = gql`
    fragment CoreFeedFields on Feed {
        id
        title
        text
        media
        url
        sentiment
        postedAt
        profile {
            id
            username
            name
            description
            imageUrl
            url
            joinedAt
        }
    }
`