import React, { useReducer, createContext } from 'react'

const initialState = {
    theme: 'dark'
}

if (localStorage.getItem('theme')) {
    initialState.theme = localStorage.getItem('theme')
}

const SettingsContext = createContext({
    theme: 'dark',
    updateTheme: (theme) => {}
})

const settingsReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state,
                theme: action.payload
            }
        default:
            return state
    }
}

const SettingsProvider = (props) => {
    const [state, dispatch] = useReducer(settingsReducer, initialState)

    const updateTheme = (theme) => {
        localStorage.setItem('theme', theme)
        dispatch({
            type: 'UPDATE',
            payload: theme
        })
    }

    return (
        <SettingsContext.Provider
            value={{ theme: state.theme, updateTheme }}
            { ...props }
        />
    )
}

export { SettingsContext, SettingsProvider }