import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import { logos } from '../../utils/platform-logos'
import ReactTimeAgo from 'react-time-ago'
import Sentiment from '../partials/Sentiment'

const Comment = ({ comment, platform }) => {
    const [profileImage, setProfileImage] = useState(comment.profile.imageUrl)

    return (
        <div className="d-flex">
            <Image
                className="avatar-30 rounded-circle me-3 img-fluid"
                src={profileImage ? profileImage : logos[platform.name] }
                alt={comment.profile.username}
                onError={() => setProfileImage(logos[platform.name])}
            />
            <div className="w-100">
                <h6 className="mb-1">{comment.profile.username}</h6>
                <p className="mb-1">{comment.text}</p>
                <p className="text-muted"><ReactTimeAgo date={new Date(comment.postedAt)} locale="en-US"/></p>
                <Sentiment feed={comment} />
            </div>
        </div>
    )
}

export default Comment