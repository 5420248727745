import { useEffect, useRef, useState } from 'react'
import DOTS from 'vanta/dist/vanta.dots.min'

export const useDotsAnimation = () => {
    const [animation, setAnimation] = useState(null)
    const animationRef = useRef(null)

    useEffect(() => {
        if (!animation) {
            setAnimation(DOTS({
                el: animationRef.current,
                mouseControls: true,
                touchControls: true,
                gyroControls: false,
                minHeight: 200.00,
                minWidth: 200.00,
                scale: 1.00,
                scaleMobile: 1.00,
                color: 0x427EEB,
                backgroundColor: 0x19203A,
                size: 3.70,
                showLines: false
            }))
        }

        return () => {
            if (animation) {
                animation.destroy()
            }
        }
    }, [animation])

    return { animationRef }
}