import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Dropdown, Button, Image } from 'react-bootstrap'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import client from '../utils/apollo-client'
import { InView } from 'react-intersection-observer'
import ScrollToTop from 'react-scroll-to-top'
import Card from '../components/common/Card'
import CustomToggle from '../layouts/components/CustomToggle'
import Loader from '../layouts/components/Loader'
import { logos } from '../utils/platform-logos'
import FeedCard from '../components/feed/FeedCard'
import Masonry from '../components/common/Masonry'
import ResponsiveMasonry from '../components/common/ResponsiveMasonry'
import { CORE_FEED_FIELDS } from '../graphql/feed-fragments'
import _ from 'lodash'

const GET_PLATFORMS = gql`
    query Platforms($options: PlatformOptions, $where: PlatformWhere) {
        platforms(options: $options, where: $where) {
            id
            name
            type
        }
    }`

const GET_CAMPAIGN = gql`
    query Campaigns {
        campaigns {
            categories
        }
    }`

const GET_CATEGORIES = gql`
    query Categories($where: CategoryWhere) {
        categories(where: $where) {
            id
            name
        }
    }`

const GET_FEEDS = gql`
    ${CORE_FEED_FIELDS}
    query Feeds($where: FeedWhere, $options: FeedOptions) {
        feeds(where: $where, options: $options) {
            ...CoreFeedFields
            comments {
                ...CoreFeedFields
            }
            keywords {
                id
                value
            }
        }
    }`

const Communities = () => {
    const [platform, setPlatform] = useState(null)
    const [audiences, setAudiences] = useState(null)
    const [category, setCategory] = useState(null)
    const [heights, setHeights] = useState({})
    const [isFullyLoaded, setFullyLoaded] = useState(false)

    const platforms = useQuery(GET_PLATFORMS, {
        client,
        variables: { options: { sort: [{ order: 'ASC' }] }, where: { status: 'ACTIVE' } },
        onCompleted: () => {
            setPlatform(platforms.data.platforms[0])
            getFeeds({
                variables: { where: { platform: { id: platforms.data.platforms[0].id }, type: null }, options: { sort: [{ postedAt: 'DESC' }], limit: 18, offset: 0 } }
            })
        }
    })

    const [getCategories, categories] = useLazyQuery(GET_CATEGORIES)

    const campaign = useQuery(GET_CAMPAIGN, {
        onCompleted: () => {
            const campaignCategories = campaign.data.campaigns[0].categories ? JSON.parse(campaign.data.campaigns[0].categories) : []
            const aliases = _.map(campaignCategories, (category) => category.toLowerCase())
            getCategories({
                variables: { where: { alias_IN: aliases } }
            })
        }
    })

    const [getFeeds, feeds] = useLazyQuery(GET_FEEDS, )

    const onChangePlatform = (platform) => {
        setFullyLoaded(false)
        setPlatform(platform)
        getFeeds({
            variables: {
                where: {
                    platform: {
                        id: platform?.id
                    },
                    type: null,
                    profile: {
                        categories_SINGLE: {
                            id: category?.id
                        }
                    }
                },
                options: {
                    sort: [{
                        postedAt: 'DESC'
                    }],
                    limit: 18,
                    offset: 0
                }
            }
        })
    }

    const onChangeCategory = (category) => {
        setFullyLoaded(false)
        setCategory(category)
        getFeeds({
            variables: {
                where: {
                    platform: {
                        id: platform?.id
                    },
                    type: null,
                    profile: {
                        categories_SINGLE: {
                            id: category?.id
                        }
                    }
                },
                options: {
                    sort: [{
                        postedAt: 'DESC'
                    }],
                    limit: 18,
                    offset: 0
                }
            }
        })
    }

    const onLoadMore = async () => {
        const result = await feeds.fetchMore({
            variables: { options: { sort: [{ postedAt: 'DESC' }], limit: 18, offset: feeds.data?.feeds.length } },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    feeds: [...prev.feeds, ...fetchMoreResult.feeds]
                })
            }
        })
        setFullyLoaded(!result.data.feeds.length)
    }

    const handleHeightChange = (index, height) => {
        setHeights((prevState) => {
            return {
                ...prevState,
                [index]: height
            }
        })
    }

    // Show categories sorted by ratio and if ratio > 5
    useEffect(() => {
        if (categories?.data?.categories && !categories.loading) {
            const array = _.map(categories.data.categories, (category) => {
                return {
                    id: category.id,
                    name: category.name,
                    value: category.ratio,
                }
            })

            setAudiences(array)
        }
    }, [categories])

    return (
        <Fragment>
            { platforms.loading || categories.loading ? (
                <Loader />
            ) : (
                <Row style={{marginBottom: '20px'}}>
                    <Col lg="12">
                        <Card>
                            <Card.Header className="align-items-center flex-wrap">
                                <h4 className="mb-0">
                                    <i className="icon">
                                        <svg className="icon-25" width="25" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z" fill="currentColor" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.9805 13.2901C11.2705 13.2801 10.7005 12.7101 10.7005 12.0001C10.7005 11.3001 11.2805 10.7201 11.9805 10.7301C12.6905 10.7301 13.2605 11.3001 13.2605 12.0101C13.2605 12.7101 12.6905 13.2901 11.9805 13.2901ZM7.3701 13.2901C6.6701 13.2901 6.0901 12.7101 6.0901 12.0101C6.0901 11.3001 6.6601 10.7301 7.3701 10.7301C8.0801 10.7301 8.6501 11.3001 8.6501 12.0101C8.6501 12.7101 8.0801 13.2801 7.3701 13.2901ZM15.3103 12.0101C15.3103 12.7101 15.8803 13.2901 16.5903 13.2901C17.3003 13.2901 17.8703 12.7101 17.8703 12.0101C17.8703 11.3001 17.3003 10.7301 16.5903 10.7301C15.8803 10.7301 15.3103 11.3001 15.3103 12.0101Z" fill="currentColor" />
                                        </svg>
                                    </i>{' '}
                                    {category ? category.name : "All Communities"} - {platform?.name}{' '}
                                    <Button variant="sm bg-white rounded-circle p-2 no-pointer">
                                        <Image src={logos[platform?.name]} alt={platform?.name} className="img-fluid object-contain" style={{width: '20px', height: '20px'}}/>
                                    </Button>
                                </h4>
                                <div className="d-flex flex-row">
                                    <Dropdown style={{ marginRight: '10px'}}>
                                        <Dropdown.Toggle as={CustomToggle} href="#" variant=" text-secondary dropdown-toggle" id="dropdownMenuButton4" aria-expanded="false">
                                            Select Community
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton4">
                                            {audiences && audiences.map((item) => (
                                                <li key={item.id}>
                                                    <Dropdown.Item href="#" onClick={() => onChangeCategory(item)}>{item.name}</Dropdown.Item>
                                                </li>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} variant="text-secondary dropdown-toggle">
                                            Select Platform
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu aria-labelledby="dropdownMenuButtonPlatform">
                                            {platforms.data.platforms.map((item) => {
                                                return (
                                                    <li key={item.id}>
                                                        <Dropdown.Item href="#" onClick={() => onChangePlatform(item)}>
                                                            <Button variant="sm bg-white rounded-circle no-pointer" style={{width: '22px', padding: 0}}>
                                                                <Image src={logos[item.name]} alt={item.name} className="img-fluid object-contain" style={{width: '10px', height: '10px'}}/>
                                                            </Button>{' '}
                                                            {item.name}
                                                        </Dropdown.Item>
                                                    </li>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Card.Header>
                            <Card.Body>
                            </Card.Body>
                        </Card>

                        <Row>
                            {!feeds?.loading && (
                                <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 900: 2, 1200: 3}}>
                                    <Masonry gutter={'32px'} heights={heights}>
                                        {feeds.data?.feeds.map((feed) => (
                                            <FeedCard key={feed.id} data={feed} platform={platform} index={feed.id} onHeightChange={handleHeightChange}/>
                                        ))}
                                        <InView
                                            onChange={inView => {
                                                if (inView) {
                                                    onLoadMore()
                                                }
                                            }}
                                        />
                                    </Masonry>
                                </ResponsiveMasonry>
                            )}
                            {!isFullyLoaded && (
                                <Loader fullScreen={false}/>
                            )}
                        </Row>
                    </Col>
                </Row>
            )}
            <ScrollToTop smooth color="white" className="turbamedia-fab"/>
        </Fragment>
    )
}

export default Communities