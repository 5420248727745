import React, { Fragment, useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Row } from 'react-bootstrap'
import Loader from '../layouts/components/Loader'
import AudienceCounter from '../components/dashboard/AudienceCounter'
import AudienceChart from '../components/dashboard/AudienceChart'
import AudiencePieChart from '../components/dashboard/AudiencePieChart'
import PlatformAudienceChart from '../components/dashboard/PlatformAudienceChart'
import AudienceBySegmentChart from '../components/dashboard/AudienceBySegmentChart'
import _ from 'lodash'

const GET_PLATFORMS = gql`
    query Platforms($options: PlatformOptions, $where: PlatformWhere) {
        platforms(options: $options, where: $where) {
            id
            name
        }
    }`

const GET_CAMPAIGN = gql`
    query Campaigns {
        campaigns {
            keywords
            audience
            audienceByKeywords
        }
    }`

const Dashboard = () => {
    const [totalAudience, setTotalAudience] = useState({})
    const [audience, setAudience] = useState([])
    const [keywords, setKeywords] = useState([])

    const platforms = useQuery(GET_PLATFORMS, {
        variables: { options: { sort: [{ order: 'ASC' }] }, where: { status: 'ACTIVE' } }
    })

    const campaign = useQuery(GET_CAMPAIGN)

    useEffect(() => {
        if (!campaign.loading && campaign?.data?.campaigns && !platforms.loading && platforms?.data?.platforms) {
            let data

            if (campaign.data.campaigns[0].audience !== null) {
                data = JSON.parse(campaign.data.campaigns[0].audience)
            } else {
                const item = {
                    audience: 0,
                    audienceThisWeek: Array(7).fill(0),
                    audienceThisMonth: Array(30).fill(0),
                    audienceThisYear: Array(12).fill(0)
                }
                data = Object.values(platforms.data.platforms).reduce((acc, platform) => {
                    acc[platform.name] = item
                    return acc
                }, {})
            }

            setAudience(data)

            const year = []
            const month = []
            const week = []

            Object.values(data).forEach(({ audienceThisYear }) => {
                audienceThisYear.forEach((value, index) => {
                    if (year[index] === undefined) {
                        year[index] = value
                    } else {
                        year[index] += value
                    }
                })
            })

            Object.values(data).forEach(({ audienceThisMonth }) => {
                audienceThisMonth.forEach((value, index) => {
                    if (month[index] === undefined) {
                        month[index] = value
                    } else {
                        month[index] += value
                    }
                })
            })

            Object.values(data).forEach(({ audienceThisWeek }) => {
                audienceThisWeek.forEach((value, index) => {
                    if (week[index] === undefined) {
                        week[index] = value
                    } else {
                        week[index] += value
                    }
                })
            })

            setTotalAudience({
                audienceThisWeek: week,
                audienceThisMonth: month,
                audienceThisYear: year
            })

            if (campaign.data.campaigns[0].audienceByKeywords !== null) {
                setKeywords(JSON.parse(campaign.data.campaigns[0].audienceByKeywords))
            }
        }
    }, [campaign, platforms])

    return (
        <Fragment>
            { platforms.loading || campaign.loading || audience.length === 0 ? (
                <Loader />
            ) : (
                <Row>
                    <AudienceCounter
                        platforms={platforms.data.platforms}
                        data={audience}
                    />
                    {!_.isEmpty(totalAudience) && (
                        <AudienceChart name={'Audience Built across All Platforms'} data={totalAudience}/>
                    )}
                    <AudiencePieChart
                        name={'Audience Structure'}
                        data={platforms.data.platforms}
                        audience={audience}
                    />
                    <PlatformAudienceChart
                        name={'Audience Built per Platform'}
                        data={platforms.data.platforms}
                        audience={audience}
                    />
                    {!_.isEmpty(totalAudience) && !campaign.loading && (
                        <AudienceBySegmentChart
                            name={'Audience by Segment'}
                            data={keywords}
                            keywords={JSON.parse(campaign.data.campaigns[0].keywords)}
                        />
                    )}
                </Row>
            )}
        </Fragment>
    )
 }

 export default Dashboard