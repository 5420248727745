import React, { memo, Fragment } from 'react'
import Card from '../common/Card'
import CountUp from 'react-countup'
import CircularProgressBar from '../common/CircularProgressBar'

const upArrow = <svg className="" width="24" height="24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z" />
</svg>

const AnalyticsCard = memo((props) => {
    const duration = 3
    const start = 0

    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <div className="progress-widget">
                        <CircularProgressBar
                            stroke={props.color}
                            width="60px"
                            height="60px"
                            Linecap='rounded'
                            trailstroke='#ddd'
                            strokewidth="4px"
                            style={{width: 60, height: 60}}
                            value={100} id="test">
                            {upArrow}
                        </CircularProgressBar>
                        <div className="progress-detail">
                            <p className="mb-2">{props.content}</p>
                            <h4 className="counter"><CountUp start={start} end={props.count} duration={duration}/></h4>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Fragment>
    )
})

AnalyticsCard.displayName = 'AnalyticsCard'
export default AnalyticsCard