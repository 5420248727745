import React from 'react'
import { Button, Col, Image } from 'react-bootstrap'
import CountUp from 'react-countup'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import Card from '../common/Card'
import { logos } from '../../utils/platform-logos'
import 'swiper/css'
import 'swiper/css/navigation'

const AudienceCounter = ({ platforms, data }) => {
    const duration = 3
    const start = 0

    return (
        <Col lg={12} md={12}>
            <div className="overflow-hidden d-slider1">
                <Swiper as="ul" className="p-0 m-0 mb-2 swiper-wrapper list-inline"
                        slidesPerView={5}
                        spaceBetween={32}
                        modules={[Navigation]}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev'
                        }}
                        breakpoints={{
                            320: { slidesPerView: 1 },
                            550: { slidesPerView: 2 },
                            991: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1500: { slidesPerView: 5 },
                            1920: { slidesPerView: 6 },
                            2040: { slidesPerView: 7 },
                            2440: { slidesPerView: 8 }
                        }} data-aos="fade-up" data-aos-delay="700"
                >
                    {
                        platforms.map((platform, index) => {
                            return (
                                <SwiperSlide className="card card-slide" key={index}>
                                    <Card.Body>
                                        <div className="progress-widget" >
                                            <Button variant="sm bg-white rounded-circle p-2 no-pointer">
                                                <Image src={logos[platform.name]} alt={platform.name} className="img-fluid avatar-30 object-contain"/>
                                            </Button>
                                            <div className="progress-detail">
                                                <p  className="mb-2">{platform.name}</p>
                                                <h4 className="counter"><CountUp  start={start} end={data[platform.name].audience} duration={duration}/></h4>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                <div className="swiper-button swiper-button-next"></div>
                <div className="swiper-button swiper-button-prev"></div>
            </div>
        </Col>
    )
}

export default AudienceCounter