import { Col, Dropdown } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import Card from '../common/Card'
import CustomToggle from '../../layouts/components/CustomToggle'
import { useChart } from '../../utils/chart-hook'

const AudienceChart = ({ name, data }) => {
    const {
        series,
        options,
        currentPeriod,
        changePeriod
    } = useChart('bar', data, ['audienceThisWeek', 'audienceThisMonth', 'audienceThisYear'], 'Audience')

    return (
        <Col md={12} xl={7}>
            <Card data-aos="fade-up" data-aos-delay="1000">
                <div className="flex-wrap card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">{name}</h4>
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} href="#" variant=" text-secondary" id="dropdownMenuButton3" aria-expanded="false">
                            {currentPeriod}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton3">
                            <li><Dropdown.Item href="#" onClick={() => changePeriod('week')}>This Week</Dropdown.Item></li>
                            <li><Dropdown.Item href="#" onClick={() => changePeriod('month')}>This Month</Dropdown.Item></li>
                            <li><Dropdown.Item href="#" onClick={() => changePeriod('year')}>This Year</Dropdown.Item></li>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Card.Body>
                    <Chart className="d-activity" options={options} series={series} type="bar" height="300" />
                </Card.Body>
            </Card>
        </Col>
    )
}

export default AudienceChart