import React, { memo, Fragment, useState, useEffect } from 'react'
import { Dropdown, Modal, Button, Table } from 'react-bootstrap'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import Card from '../common/Card'
import CustomToggle from '../../layouts/components/CustomToggle'
import DataTable from '../common/DataTable'
import Loader from '../../layouts/components/Loader'
import _ from 'lodash'

const GET_LANDING_PAGES = gql`
    query LandingPages($where: LandingPageWhere) {
        landingPages(where: $where) {
            id
            title
        }
    }`

const GET_LEADS = gql`
    query Leads($where: LeadWhere, $options: LeadOptions) {
        leads(where: $where, options: $options) {
            id
            fields
            createdAt
        }
    }`

const viewIcon = `<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" height="32">
    <path opacity="0.4" d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z" fill="currentColor"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z" fill="currentColor"></path>
    </svg>`

const columns = [
    {
        title: 'Created At',
        data: 'createdAt'
    },
    {
        title: '',
        data: 'id',
        render: (data) => {
            return (
                '<div class="flex align-items-center list-leads-action"><a class="btn btn-icon btn-primary view-icon opacity-10 tooltip" data-target="' + data + '"><span class="btn-inner">' +
                viewIcon + '</span><span class="tooltiptext">View</span></a></div>'
            )
        }
    }
]

const CampaignLeads = memo(() => {
    const [getLeads, leads] = useLazyQuery(GET_LEADS)
    const landingPages = useQuery(GET_LANDING_PAGES, {
        variables: { where: { status: 'ACTIVE' } },
        onCompleted: () => {
            setCurrentPage(landingPages.data.landingPages[0])
            getLeads({
                variables: { where: { landingPage: { id: landingPages.data.landingPages[0].id } }, options: { sort: [{ createdAt: 'DESC' }] } }
            })
        }
    })

    const [currentPage, setCurrentPage] = useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const [details, setDetails] = useState(null)
    const [leadsData, setLeadsData] = useState(null)
    const [fields, setFields] = useState([
        {
            title: 'Name',
            data: 'Name',
        },
        {
            title: 'Email',
            data: 'Email',
        },
        {
            title: 'Phone',
            data: 'Phone',
        }
    ])

    const changeLandingPage = (page) => {
        setCurrentPage(page)
        getLeads({
            variables: { where: { landingPage: { id: page.id } }, options: { sort: [{ createdAt: 'DESC' }] } }
        })
    }

    const onClickViewButton = (event) => {
        const leadId = event.currentTarget.getAttribute('data-target')
        const lead = _.find(leads.data.leads, (lead) => lead.id === leadId)
        setDetails(lead)
        setShowDetails(true)
    }

    useEffect(() => {
        const viewButtons = document.getElementsByClassName('view-icon')

        _.each(viewButtons, (element) => {
            element.addEventListener('click', onClickViewButton)
        })

        return () => {
            _.each(viewButtons, (element) => {
                element.removeEventListener('click', onClickViewButton)
            })
        }
    })

    useEffect(() => {
        const array = []
        if (leads.data) {
            _.each(leads.data.leads, (item, key) => {
                const fields = JSON.parse(item.fields)
                const columns = Object.keys(fields).splice(0, 3)
                const values = Object.values(fields).splice(0, 3)

                array.push({
                    id: item.id,
                    [columns[0]]: values[0],
                    [columns[1]]: values[1],
                    [columns[2]]: values[2],
                    createdAt: new Date(Date.parse(item.createdAt)).toLocaleString('en-GB'),
                })

                if (key + 1 === leads.data.leads.length) {
                    const updatedColumns = _.map(columns, (column) => {
                        return {
                            title: column,
                            data: column,
                        }
                    })

                    setFields(updatedColumns)
                }
            })
            setLeadsData(array)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leads.data])

    return (
        <Fragment>
            { landingPages.loading ? (
                <Loader fullScreen={false}/>
            ) : (
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h4 className="mb-0 card-title">Campaign Leads - {currentPage?.title}</h4>
                        <div className="d-flex flex-row">
                            <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} href="#" variant=" text-secondary" id="dropdownMenuButton3" aria-expanded="false">
                                    Select Landing Page
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton3">
                                    {landingPages.data.landingPages.map((page) => (
                                        <li key={page.id}><Dropdown.Item href="#" onClick={() => changeLandingPage(page)}>{page.title}</Dropdown.Item></li>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Card.Header>
                    <Card.Body className="card-body px-0 pb-0">
                        <div className="table-responsive custom-table-2">
                            <DataTable
                                data={leadsData}
                                columns={[...fields, ...columns]}
                                iscolumnfilter = 'data-table-filter'
                            />
                        </div>
                    </Card.Body>
                </Card>
            )}
            <Modal show={showDetails} onHide={() => setShowDetails(false)}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Lead Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bd-example table-responsive">
                        <Table className="table-striped">
                            <tbody>
                            {details?.fields && Object.keys(JSON.parse(details.fields)).map((key, index) => (
                                <tr key={index}>
                                    <th scope="row" key={index}>{key}</th>
                                    <td style={{whiteSpace: 'pre-line'}}>{Object.values(JSON.parse(details.fields))[index]}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowDetails(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
})

export default CampaignLeads