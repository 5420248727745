import _ from 'lodash'
import { removeClass, addClass } from './dom'

export const updateBodyClass = (choices, value) => {
    if(choices.length > 0) {
        choices.forEach((other) => {
            removeClass('body', other)
        });
    }
    if(_.isArray(value)) {
        _.forEach(value, (val) => {
            addClass('body', val)
        })
    } else {
        addClass('body', value)
    }
}

export const updateThemeScheme = (value) => {
    let className = []
    if(value === 'auto') {
        className.push('auto')
        if (matchMedia("(prefers-color-scheme: light)").matches) {
            className.push('light')
        } else {
            className.push('dark')
        }
    } else {
        className.push(value)
    }
    className.filter((x) => x !== 'auto')
    updateBodyClass(['light', 'dark', 'auto'], className)
}