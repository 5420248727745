import React, { Fragment, useContext, useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Row, Col, Dropdown } from 'react-bootstrap'
import { AuthContext } from '../context/auth-context'
import CustomToggle from '../layouts/components/CustomToggle'
import AnalyticsCard from '../components/campaigns/AnalyticsCard'
import Card from '../components/common/Card'
import ProgressBar from '../components/common/ProgressBar'
import ConversionsChart from '../components/campaigns/ConversionsChart'
import CampaignCostChart from '../components/campaigns/CampaignCostChart'
import PublisherPlatformChart from '../components/campaigns/PublisherPlatformChart'
import ConversionRateBarChart from '../components/campaigns/ConversionRateBarChart'
import CampaignLeads from '../components/campaigns/CampaignLeads'
import { showNotification } from '../utils/notifications'
import { ToastContainer } from 'react-toastify'
import Loader from '../layouts/components/Loader'

const GET_INSIGHTS = gql`
    query Publishers($where: PublisherCampaignsConnectionWhere, $publishersWhere2: PublisherWhere) {
        publishers(where: $publishersWhere2) {
            name
            campaignsConnection(where: $where) {
                edges {
                    impressions
                    reach
                    engagement
                    conversion
                    spend
                    costPerConversion
                    publishers
                    conversionsByMonths
                    conversionPerAds
                }
            }
        }
    }`

const GET_LANDING_PAGES = gql`
    query LandingPages($where: LandingPageWhere) {
        landingPages(where: $where) {
            id
            title
        }
    }`

const Campaign = () => {
    const { user } = useContext(AuthContext)
    const [data, setData] = useState({})

    const insights = useQuery(GET_INSIGHTS)
    const creatives = useQuery(GET_LANDING_PAGES)


    useEffect(() => {
        if (insights.data?.publishers.length > 0) {
            const data = insights.data.publishers.find((publisher) => publisher.name === 'Meta').campaignsConnection.edges[0]
            setData(data)
        }
    }, [insights.data])

    return (
        <Fragment>
            { insights.loading || creatives.loading || (Object.keys(data).length === 0 && data.constructor === Object) ? (
                <Loader />
            ) : (
                <Fragment>
                    <ToastContainer/>
                    <div className="mb-5">
                        <div className="d-flex flex-wrap justify-content-between mb-4">
                            <h3 className="mb-0">Campaign Summary</h3>
                            {/*<Dropdown>*/}
                            {/*    <Dropdown.Toggle as={CustomToggle} variant=" text-secondary dropdown-toggle">*/}
                            {/*        This Week*/}
                            {/*    </Dropdown.Toggle>*/}
                            {/*    <Dropdown.Menu aria-labelledby="dropdownMenuButton1">*/}
                            {/*        <li><Dropdown.Item href="#">This Week</Dropdown.Item></li>*/}
                            {/*        <li><Dropdown.Item href="#">This Month</Dropdown.Item></li>*/}
                            {/*        <li><Dropdown.Item href="#">This Year</Dropdown.Item></li>*/}
                            {/*    </Dropdown.Menu>*/}
                            {/*</Dropdown>*/}
                        </div>

                        <Row>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? 862 : data.engagement} content="Audience Engagement" color="#427EEB" />
                            </Col>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? '76%' : data.conversion} content="Audience Conversion" color="#08B1BA" />
                            </Col>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? 1024 : data.impressions} content="Impressions" color="#427EEB" />
                            </Col>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? '4%' : data.reach} content="Reach" color="#08B1BA" />
                            </Col>
                        </Row>

                        <Row>
                            <ConversionsChart data={data} />

                            <Col lg="5" className="mt-0 mt-sm-md-0">
                                <Card>
                                    <Card.Header>
                                        <h4 className="mb-0">Cost and Efficiency</h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col sm="12">
                                                <CampaignCostChart data={data} />
                                            </Col>
                                        </Row>
                                        <div className="d-flex mt-3 mt-sm-0">
                                            <span className="avatar-50 bg-soft-info rounded">
                                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" height="32">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z" fill="currentColor"></path>
                                                    <path opacity="0.4" d="M16.9739 6.77432C16.977 6.85189 16.9621 6.92913 16.9303 7H15.4932C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H7.00989C6.9967 6.92535 6.9967 6.84898 7.00989 6.77432C7.12172 4.10591 9.32499 2 12.0049 2C14.6849 2 16.8882 4.10591 17 6.77432H16.9739Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <div className="w-100 ms-3">
                                                <div className="d-flex justify-content-between">
                                                    <h6>Cost Per Conversion</h6>
                                                    <p>${process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? 8.55 : data.costPerConversion}</p>
                                                </div>
                                                <ProgressBar
                                                    softcolors="info"
                                                    color="info"
                                                    className="shadow-none w-100"
                                                    value={process.env.REACT_APP_DEMO_USER_ID.includes(user.id) || data.costPerConversion ? 45 : 0}
                                                    minvalue={0}
                                                    maxvalue={100}
                                                    style={{height: "8px"}}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <PublisherPlatformChart name={'Publisher Platforms'} data={data}/>
                            <ConversionRateBarChart name={'Creative Conversion Rate'} data={data} creatives={creatives.data.landingPages}/>
                        </Row>

                        {/*<Col md={12}>*/}
                            {/*<CampaignLeads />*/}
                        {/*</Col>*/}

                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default Campaign