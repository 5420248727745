import React, { memo, Fragment } from 'react'

const Loader = memo(({ fullScreen = true }) => {
    const style = {
        height: fullScreen ? '85vh' : '180px'
    }

    return (
        <Fragment>
            <div className="iq-loader-box" style={style}>
                <div className="iq-loader-6"> <span className="inner inner1"></span>
                    <span className="inner inner2"></span>
                    <span className="inner inner3"></span>
                </div>
            </div>
        </Fragment>
    )
})

Loader.displayName = 'Loader'
export default Loader
