import React, { memo, useContext } from 'react'
import { SettingsContext } from '../../context/settings-context'
import { updateThemeScheme } from '../../utils/theme-settings'

const RadioBtn = memo((props) => {
    const { updateTheme, theme } = useContext(SettingsContext)

    const changeTheme = (type) => {
        updateThemeScheme(type)
        updateTheme(type)
    }

    const radioCheckValue = (value) => {
        if (theme === value) {
            return true
        }
        return false
    }
    return (
        <div>
            <input type="radio" value={props.value} className="btn-check" name={props.btnName} id={props.id}  autoComplete="off" defaultChecked={radioCheckValue(props.value)} onClick={() => {changeTheme(props.value)}}/>
            <label className={`btn btn-border  ${props.labelclassName}`} htmlFor={props.id}>
                {props.children}
            </label>
            {props.imgComponent ? <span className="mt-2"> {props.label || ''} </span> : ''}
        </div>
    )
})

RadioBtn.displayName = 'RadioBtn'
export default RadioBtn