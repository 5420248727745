import React, { Fragment, useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import Card from '../common/Card'
import Progress from '../common/Progress'
import Loader from '../../layouts/components/Loader'
import _ from 'lodash'

const GET_CATEGORIES = gql`
    query Categories($where: CategoryWhere) {
        categories(where: $where) {
            name
            audience
            ratio
        }
    }`

const GET_CAMPAIGN = gql`
    query Campaigns {
        campaigns {
           categories
        }
    }`

const ClassificationReport = () => {
    const [getCategories, categories] = useLazyQuery(GET_CATEGORIES)
    const campaign = useQuery(GET_CAMPAIGN, {
        onCompleted: () => {
            const campaignCategories = campaign.data.campaigns[0].categories ? JSON.parse(campaign.data.campaigns[0].categories) : []
            const aliases = _.map(campaignCategories, (category) => category.toLowerCase())
            getCategories({
                variables: { where: { alias_IN: aliases } }
            })
        }
    })

    const [data, setData] = useState(null)

    // Show categories sorted by ratio and if ratio > 10
    useEffect(() => {
        if (categories?.data?.categories && !categories.loading) {
            const array = _.map(categories.data.categories, (category) => {
                return {
                    name: category.name,
                    audience: category.audience,
                    ratio: category.ratio,
                    softcolor: category.ratio < 50 ? 'primary' : 'success',
                    color: category.ratio < 50 ? 'primary' : 'success',
                    value: category.ratio,
                }
            })
                .sort((a, b) => b.audience - a.audience)

            setData(array)
        }
    }, [categories])

    return (
        <Card className="table-responsive" data-aos="fade-up" data-aos-delay="600" style={{height: '525px'}}>
            { categories.loading || !data ? (
                <Loader fullScreen={false} />
            ) : (
                <Fragment>
                    <div className="flex-wrap card-header d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="mb-2 card-title">Generative AI Classification</h4>
                            <p className="mb-0">
                                <svg className="me-2 text-primary" width="24" height="24" viewBox="0 0 24 24">
                                    <path fill="currentColor"
                                          d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                                Sub Audiences
                            </p>
                        </div>
                    </div>
                    <Card.Body className="p-0">
                        <Table striped responsive className="mb-0">
                            <thead>
                            <tr>
                                <th>SUB AUDIENCES</th>
                                <th>AUDIENCE</th>
                                <th>RATIO</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <h6>{item.name}</h6>
                                                </div>
                                            </td>
                                            <td>{item.audience}</td>
                                            <td>
                                                <div className="mb-2 d-flex align-items-center">
                                                    <h6>{item.ratio}%</h6>
                                                </div>
                                                <Progress softcolors={item.softcolor} color={item.color} className="shadow-none w-100" value={item.value} minvalue={0} maxvalue={100} style={{height: "4px"}} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Fragment>
            )}
        </Card>
    )
}

export default ClassificationReport