import React, { useState, useEffect, useRef } from 'react'
import { Dropdown, Image, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'
import ReactTimeAgo from 'react-time-ago'
import Card from '../common/Card'
import CustomToggle from '../../layouts/components/CustomToggle'
import Comments from './Comments'
import Sentiment from '../partials/Sentiment'
import { logos } from '../../utils/platform-logos'
import _ from 'lodash'

const FeedCard = ({ data, platform, index, onHeightChange }) => {
    const childRef = useRef()
    const [profileImage, setProfileImage] = useState(data.profile.imageUrl)
    const media = JSON.parse(data.media)
    const keywords = _.map(data.keywords, (keyword) => keyword.value)

    const openLink = (url) => {
        if (url) {
            window.open(url, '_blank', 'noreferrer')
        }
    }

    useEffect(() => {
        const childHeight = childRef.current.clientHeight
        onHeightChange(index, childHeight)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childRef.current?.clientHeight])

    return (
        <div ref={childRef}>
            <Card style={{marginBottom: 0, width: '100%'}}>
                <Card.Header className=" pb-0">
                    <div className="header-title">
                        <div className="d-flex justify-content-center flex-wrap gap-3">
                            <OverlayTrigger placement="top" overlay={
                                <Tooltip>
                                    Open Profile in {platform.name}
                                </Tooltip>
                            }>
                                <div className="media-support-user-img" role="button" onClick={() => openLink(data.profile.url)}>
                                    <Image
                                        className="img-fluid rounded-circle p-1 border border-2 border-primary border-dotted avatar-50"
                                        src={profileImage ? profileImage : logos[platform.name] }
                                        alt={data.profile.username}
                                        onError={() => setProfileImage(logos[platform.name])}
                                    />
                                </div>
                            </OverlayTrigger>
                            <div className="media-support-info">
                                <div className="d-flex align-items-center mb-2 gap-2">
                                    <OverlayTrigger placement="top" overlay={
                                        <Tooltip >
                                            {data.profile.name}
                                        </Tooltip>
                                    }>
                                    <h6 className="mb-0">{data.profile.username}</h6>
                                    </OverlayTrigger>
                                </div>
                                <p className="mb-0 text-muted"><ReactTimeAgo date={new Date(data.postedAt)} locale="en-US"/></p>
                            </div>
                        </div>
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} className="">
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10 20.6788C10 21.9595 11.0378 23 12.3113 23C13.5868 23 14.6265 21.9595 14.6265 20.6788C14.6265 19.3981 13.5868 18.3576 12.3113 18.3576C11.0378 18.3576 10 19.3981 10 20.6788ZM10 12.0005C10 13.2812 11.0378 14.3217 12.3113 14.3217C13.5868 14.3217 14.6265 13.2812 14.6265 12.0005C14.6265 10.7198 13.5868 9.67929 12.3113 9.67929C11.0378 9.67929 10 10.7198 10 12.0005ZM12.3113 5.64239C11.0378 5.64239 10 4.60192 10 3.3212C10 2.04047 11.0378 1 12.3113 1C13.5868 1 14.6265 2.04047 14.6265 3.3212C14.6265 4.60192 13.5868 5.64239 12.3113 5.64239Z" fill="#8A92A6"/>
                            </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item className=" d-flex align-items-center">
                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.4" d="M11.7761 21.8374C9.49311 20.4273 7.37081 18.7645 5.44807 16.8796C4.09069 15.5338 3.05404 13.8905 2.41735 12.0753C1.27971 8.53523 2.60399 4.48948 6.30129 3.2884C8.2528 2.67553 10.3752 3.05175 12.0072 4.29983C13.6398 3.05315 15.7616 2.67705 17.7132 3.2884C21.4105 4.48948 22.7436 8.53523 21.606 12.0753C20.9745 13.8888 19.944 15.5319 18.5931 16.8796C16.6686 18.7625 14.5465 20.4251 12.265 21.8374L12.0161 22L11.7761 21.8374Z" fill="currentColor"></path>
                                    <path d="M12.0109 22.0001L11.776 21.8375C9.49013 20.4275 7.36487 18.7648 5.43902 16.8797C4.0752 15.5357 3.03238 13.8923 2.39052 12.0754C1.26177 8.53532 2.58605 4.48957 6.28335 3.28849C8.23486 2.67562 10.3853 3.05213 12.0109 4.31067V22.0001Z" fill="currentColor"></path>
                                    <path d="M18.2304 9.99922C18.0296 9.98629 17.8425 9.8859 17.7131 9.72157C17.5836 9.55723 17.5232 9.3434 17.5459 9.13016C17.5677 8.4278 17.168 7.78851 16.5517 7.53977C16.1609 7.43309 15.9243 7.00987 16.022 6.59249C16.1148 6.18182 16.4993 5.92647 16.8858 6.0189C16.9346 6.027 16.9816 6.04468 17.0244 6.07105C18.2601 6.54658 19.0601 7.82641 18.9965 9.22576C18.9944 9.43785 18.9117 9.63998 18.7673 9.78581C18.6229 9.93164 18.4291 10.0087 18.2304 9.99922Z" fill="currentColor"></path>
                                </svg>
                                <span className="ms-2">Like Profile</span>
                            </Dropdown.Item>
                            <Dropdown.Item className=" d-flex align-items-center">
                                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.4" d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z" fill="currentColor"></path>
                                    <path d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z" fill="currentColor"></path>
                                </svg>
                                <span className="ms-2">Delete Feed</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Card.Header>
                <Card.Body>
                    <div className="user-post">
                        {data.title && (<h5 className="mb-3">{data.title}</h5>)}
                        {(media[0]?.type === 'image' || media[0]?.type === 'video') && (
                            <OverlayTrigger placement="top" overlay={
                                <Tooltip>
                                    Open Post in {platform.name}
                                </Tooltip>
                            }>
                                {media[0]?.type === 'image' || media[0].previewImageUrl ? (
                                    <Image
                                        src={media[0]?.type === 'image' ? media[0].url : media[0].previewImageUrl}
                                        alt="post-image"
                                        style={{ width: '100%' }}
                                        className="img-fluid rounded"
                                        role="button"
                                        onClick={() => openLink(data.url)}
                                    />
                                ) : (
                                    <video controls width="100%">
                                        <source src={media[0].url} type="video/mp4"/>
                                    </video>
                                )}

                            </OverlayTrigger>
                        )}
                    </div>
                    <div className="comment-area pt-3">
                        <div>{data.text}</div>
                        {data.comments?.length > 0 && (
                            <Comments
                                comments={data.comments}
                                platform={platform}
                            />
                        )}
                        <div className="pt-3 pb-3">
                            {keywords.map((keyword, index) => (
                                    <Badge bg="primary" key={index} style={{marginRight: '10px'}}>{keyword}</Badge>
                                )
                            )}
                        </div>
                        <div className="btn btn-sm btn-soft-light" onClick={() => openLink(data.url)}>Read Post</div>
                        <Sentiment feed={data} style={{marginLeft: '10px'}}/>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default FeedCard