import { useContext } from 'react'
import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import { AuthContext } from '../../context/auth-context'
import Card from '../common/Card'

const PUBLISHERS = {
    audience_network: 'Audience Network',
    facebook: 'Facebook',
    instagram: 'Instagram'
}

const PublisherPlatformChart = ({ name, data }) => {
    const { user } = useContext(AuthContext)
    const publishers = JSON.parse(data.publishers)
    const keys = process.env.REACT_APP_DEMO_USER_ID.includes(user.id) || !publishers
        ? ['audience_network', 'facebook', 'instagram'] :
        Object.keys(publishers)

    const options = {
        theme: {
            monochrome: {
                enabled: true,
                color: '#427EEB',
                shadeTo: 'light'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 250
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        dataLabels: {
            style: {
                fontSize: '16px'
            }
        }
    }
    const labels = keys.map((key) => PUBLISHERS[key])
    const series = process.env.REACT_APP_DEMO_USER_ID.includes(user.id)
        ? [150, 340, 450]
        : (publishers ? Object.values(publishers) : [0, 0, 0])

    options['labels'] = labels

    return (
        <Col md={12} xl={5}>
            <Card data-aos="fade-up" data-aos-delay="1000">
                <div className="flex-wrap card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">{name}</h4>
                    </div>
                </div>
                <Card.Body>
                    <Chart className="d-activity" options={options} series={series} type="pie" height="300" />
                </Card.Body>
            </Card>
        </Col>
    )
}

export default PublisherPlatformChart