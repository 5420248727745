import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import { gql, useQuery } from '@apollo/client'
import Card from '../common/Card'
import Loader from '../../layouts/components/Loader'

const GET_SENTIMENT = gql`
    query Campaigns {
        campaigns {
            sentiment
        }
    }`

const SentimentAnalysisPieChart = ({ name }) => {
    const campaign = useQuery(GET_SENTIMENT)
    const [series, setSeries] = useState([])

    const options = {
        fill: {
            colors: ['#427EEB', '#08B1BA', '#F16A1B']
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 250
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        dataLabels: {
            style: {
                fontSize: '16px'
            }
        },
        legend: {
            markers: {
                fillColors: ['#427EEB', '#08B1BA', '#F16A1B']
            }
        }
    }

    options['labels'] = ['Positive', 'Neutral', 'Negative']

    useEffect(() => {
        if (campaign?.data?.campaigns) {
            if (campaign.data.campaigns[0]?.sentiment) {
                setSeries(campaign.data.campaigns[0]?.sentiment)
            }
        }
    }, [campaign])

    return (
        <Col md={12} xl={5}>
            { campaign.loading ? (
                <Loader fullScreen={false} />
            ) : (
                <Card data-aos="fade-up" data-aos-delay="1000">
                    <div className="flex-wrap card-header d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">{name}</h4>
                        </div>
                    </div>
                    <Card.Body>
                        <Chart className="d-activity" options={options} series={series} type="pie" height="300" />
                    </Card.Body>
                </Card>
            )}
        </Col>
    )
}

export default SentimentAnalysisPieChart