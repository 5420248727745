import React, { useContext } from 'react'
import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import { AuthContext } from '../../context/auth-context'
import Card from '../common/Card'

const CampaignCostChart = ({ data }) => {
    const { user } = useContext(AuthContext)
    const spent = Math.round(data.spend)

    const chart1 ={
        options : {
            labels: ['spent'],
            chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                },
            },
            colors: ['#08B1BA'],

            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: '#3a57e81a',
                        show: true,
                        strokeWidth: '97%',
                        opacity: 1,
                        margin: 5,
                        dropShadow: {
                            enabled: false,
                            top: 0,
                            left: 0,
                            blur: 3,
                            opacity: 0.5
                        }
                    },
                    dataLabels: {
                        name: {
                            fontSize: '16px',
                            offsetY: 20,
                        },
                        value: {
                            offsetY: -25,
                            fontSize: '40px',
                            formatter: function (val) {
                                return process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? '$75' : '$' + spent
                            }
                        },

                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
        },
        series: [spent > 0 ? 75 : 0]
    }

    return (
        <Col sm="12">
            <Chart  options={chart1.options} series={chart1.series} type="radialBar" height="375" />
        </Col>
    )
}

export default CampaignCostChart