import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

export const showNotification = (message, type, timer = 5000, button = null, callback = null) => {
    const MessageWithButton = () => (
        <div>
            {message}
            {button && (
                <button
                    style={{
                        padding: '5px 10px',
                        marginLeft: '10px',
                        backgroundColor: '#1578bb',
                        color: 'white',
                        border: 'solid 1px #00589b',
                        borderRadius: '3px',
                        cursor: 'pointer',
                    }}
                    onClick={callback}
                >
                    {button}
                </button>
            )}
        </div>
    );

    toast[type](<MessageWithButton />, {
        position: 'top-center',
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
    })
}

export const askConfirmation = (text, confirmButtonText, callback, args = null) => {
    Swal.fire({
        title: 'Are you sure?',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        backdrop: `rgba(60,60,60,0.7)`,
        confirmButtonText: confirmButtonText
    }).then((result) => {
        if (result.isConfirmed) {
            callback(args)
        }
    })
}