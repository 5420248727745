import _ from 'lodash'

export const getElems = (selector) => {
    return document.querySelectorAll(selector)
}

export const addClass = (elem, ...classNames) => {
    let _newElem = elem
    if (_.isString(_newElem)) {
        _newElem = getElems(elem)
    }
    if (_newElem.length !== undefined) {
        _.forEach(_newElem, function (elem) {
            _.forEach(classNames, function (className) {
                elem.classList.add(className)
            })
        })
    } else {
        _.forEach(classNames, function (className) {
            _newElem.classList.add(className)
        })
    }
}

export const removeClass = (elem, ...className) => {
    let _newElem = elem
    if (_.isString(_newElem)) {
        _newElem = getElems(elem)
    }
    if (_newElem.length !== undefined) {
        _.forEach(_newElem, function (elem) {
            _.forEach(className, function (className) {
                elem.classList.remove(className)
            })
        })
    } else {
        _.forEach(className, function (className) {
            _newElem.classList.remove(className)
        })
    }
}