import React, { memo, Fragment } from 'react'
import { Link } from 'react-router-dom'

const Footer = memo(() => {
    return (
        <Fragment>
            <footer className="footer default">
                <div className="footer-body">
                    <ul className="left-panel list-inline mb-0 p-0">
                        <li className="list-inline-item"><Link to="privacy-policy">Privacy Policy</Link></li>
                    </ul>
                    <div className="right-panel">
                        {new Date().getFullYear()} © Turba Media
                    </div>
                </div>
            </footer>
        </Fragment>
    )
})

Footer.displayName='Footer'
export default Footer
