import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Tab, Nav } from 'react-bootstrap'
import { gql, useQuery } from '@apollo/client'
import Loader from '../../layouts/components/Loader'
import Tooltip from '../common/Tooltip'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.css'
import Notification from '../../components/partials/Notification'
import Card from '../common/Card'
import HeaderTable from './HeaderTable'

const GET_HEADERS = gql`
    query Campaigns {
        campaigns {
            headers
            subheaders
            lastExtractedAt
        }
    }`

const GET_LANDING_PAGES = gql`
    query LandingPages($where: LandingPageWhere) {
        landingPages(where: $where) {
            id
            title
            domain
            pageSettings
        }
    }`

const ExtractionReport = () => {
    const campaign = useQuery(GET_HEADERS)
    const fp = useRef(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [tmDates, setTmDates] = useState([])
    const landingPages = useQuery(GET_LANDING_PAGES, {
        variables: { where: { status: 'ACTIVE' } }
    })

    const extractions = () => {
        let headers, subheaders
        try {
            headers = campaign.data.campaigns[0]?.headers
                ? JSON.parse(campaign.data.campaigns[0]?.headers)
                : null
        } catch (error) {
            headers = campaign.data.campaigns[0]?.headers
        }
        try {
            subheaders = campaign.data.campaigns[0]?.subheaders
                ? JSON.parse(campaign.data.campaigns[0]?.subheaders)
                : null
        } catch (error) {
            subheaders = campaign.data.campaigns[0]?.subheaders
        }

        return {
            headers: headers,
            subheaders: subheaders
        }
    }

    const date = () => {
        const data = extractions()
        try {
            let date = data.headers.find(item => item.date === selectedDate)['date']
            const dateParts = date.split('/')
            date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`)
            return (new Intl.DateTimeFormat('en-AU',{ day: '2-digit', month: 'short', year: 'numeric' })).format(date)
        } catch (error) {
            const date = campaign.data.campaigns[0].lastExtractedAt
            return campaign.data.campaigns[0]?.lastExtractedAt
                ? (new Intl.DateTimeFormat('en-AU',{ day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(date)))
                : null
        }
    }

    const headlines = () => {
        const data = extractions()
        try {
            return data.headers.find(item => item.date === selectedDate)['array']
        } catch (error) {
            return data.headers ? data.headers : []
        }
    }

    const subheadlines = () => {
        const data = extractions()
        try {
            return data.subheaders.find(item => item.date === selectedDate)['array']
        } catch (error) {
            return data.subheaders ? data.subheaders : []
        }
    }

    const handleDateChange = (selectedDates, dateStr, instance) => {
        const dateParts = dateStr.split('-')
        const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`
        setSelectedDate(formattedDate)
    }

    const onClickViewButton = (domain) => {
        const url = `https://${domain}.${process.env.REACT_APP_LANDING_PAGE_DOMAIN}`
        window.open(url, '_blank', 'noreferrer')
    }

    const emptyLines = () => {
        const lines = landingPages.data.landingPages.length
        return lines < 5
            ? Array.from({ length: 5 - lines }, (_, index) => lines + index + 1)
            : []
    }

    useEffect(() => {
        if (tmDates.length === 0) {
            let date, dates
            try {
                date = campaign.data.campaigns[0]?.headers
                    ? JSON.parse(campaign.data.campaigns[0]?.headers).pop()['date']
                    : null
                dates = campaign.data.campaigns[0]?.headers
                    ? (JSON.parse(campaign.data.campaigns[0].headers)[0].date
                        ? JSON.parse(campaign.data.campaigns[0]?.headers).map(item => item.date)
                        : [])
                    : []
            } catch (error) {
                date = null
                dates = []
            }

            if (date) {
                setSelectedDate(date)
            }

            if (dates) {
                dates = dates.map((item) => {
                    const dateParts = item.split('/')
                    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                })

                setTmDates(dates)
            }
        }
    }, [campaign, selectedDate])

    return (
        <Fragment>
            <Card className="card-block">
                { landingPages.loading || campaign.loading ? (
                    <Loader fullScreen={false}/>
                ) : (
                    <Card.Body className="card-thumbnail">
                        <div>
                            <h4 className="mt-2">Time Machine</h4>
                            {date() && (
                                <Fragment>
                                    <Flatpickr
                                        ref={fp}
                                        options={{enable: tmDates}}
                                        className="hidden-input"
                                        style={{marginLeft: '-6px'}}
                                        onChange={handleDateChange}
                                    />
                                    <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                         className="icon-24" height="24"
                                         style={{marginLeft: '-6px', cursor: 'pointer', marginBottom: '10px'}}
                                         onClick={() => {
                                             if (!fp?.current?.flatpickr) return;
                                             fp.current.flatpickr.open();
                                         }}>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M3 16.8701V9.25708H21V16.9311C21 20.0701 19.0241 22.0001 15.8628 22.0001H8.12733C4.99561 22.0001 3 20.0301 3 16.8701ZM7.95938 14.4101C7.50494 14.4311 7.12953 14.0701 7.10977 13.6111C7.10977 13.1511 7.46542 12.7711 7.91987 12.7501C8.36443 12.7501 8.72997 13.1011 8.73985 13.5501C8.7596 14.0111 8.40395 14.3911 7.95938 14.4101ZM12.0198 14.4101C11.5653 14.4311 11.1899 14.0701 11.1701 13.6111C11.1701 13.1511 11.5258 12.7711 11.9802 12.7501C12.4248 12.7501 12.7903 13.1011 12.8002 13.5501C12.82 14.0111 12.4643 14.3911 12.0198 14.4101ZM16.0505 18.0901C15.596 18.0801 15.2305 17.7001 15.2305 17.2401C15.2206 16.7801 15.5862 16.4011 16.0406 16.3911H16.0505C16.5148 16.3911 16.8902 16.7711 16.8902 17.2401C16.8902 17.7101 16.5148 18.0901 16.0505 18.0901ZM11.1701 17.2401C11.1899 17.7001 11.5653 18.0611 12.0198 18.0401C12.4643 18.0211 12.82 17.6411 12.8002 17.1811C12.7903 16.7311 12.4248 16.3801 11.9802 16.3801C11.5258 16.4011 11.1701 16.7801 11.1701 17.2401ZM7.09989 17.2401C7.11965 17.7001 7.49506 18.0611 7.94951 18.0401C8.39407 18.0211 8.74973 17.6411 8.72997 17.1811C8.72009 16.7311 8.35456 16.3801 7.90999 16.3801C7.45554 16.4011 7.09989 16.7801 7.09989 17.2401ZM15.2404 13.6011C15.2404 13.1411 15.596 12.7711 16.0505 12.7611C16.4951 12.7611 16.8507 13.1201 16.8705 13.5611C16.8804 14.0211 16.5247 14.4011 16.0801 14.4101C15.6257 14.4201 15.2503 14.0701 15.2404 13.6111V13.6011Z"
                                              fill="currentColor"></path>
                                        <path opacity="0.4"
                                              d="M3.00293 9.25699C3.01577 8.66999 3.06517 7.50499 3.15803 7.12999C3.63224 5.02099 5.24256 3.68099 7.54442 3.48999H16.4555C18.7376 3.69099 20.3677 5.03999 20.8419 7.12999C20.9338 7.49499 20.9832 8.66899 20.996 9.25699H3.00293Z"
                                              fill="currentColor"></path>
                                        <path
                                            d="M8.30465 6.59C8.73934 6.59 9.06535 6.261 9.06535 5.82V2.771C9.06535 2.33 8.73934 2 8.30465 2C7.86996 2 7.54395 2.33 7.54395 2.771V5.82C7.54395 6.261 7.86996 6.59 8.30465 6.59Z"
                                            fill="currentColor"></path>
                                        <path
                                            d="M15.6953 6.59C16.1201 6.59 16.456 6.261 16.456 5.82V2.771C16.456 2.33 16.1201 2 15.6953 2C15.2606 2 14.9346 2.33 14.9346 2.771V5.82C14.9346 6.261 15.2606 6.59 15.6953 6.59Z"
                                            fill="currentColor"></path>
                                    </svg>
                                    {' '}
                                    <span className="text-primary">
                                    {date()}
                                </span>
                                    <Tooltip
                                        text={'Click on the Calendar icon to select different date to see other Extractions'}/>
                                </Fragment>
                            )}
                            <h4 className="mt-2">Generative AI Extraction</h4>
                            <div className="d-flex gap-3">
                                {!date() && (
                                    <a className="iq-blog-adventure fs-6">In Progress...</a>
                                )}
                            </div>
                        </div>
                        {date() && (
                            <Tab.Container defaultActiveKey="first">
                                <div className="tab-bottom-bordered">
                                    <Nav className="mb-0 nav-tabs justify-content-around d-flex" id="nav-tab1" role="tablist">
                                        <Nav.Link className="py-3 " eventKey="first" data-bs-toggle="tab" data-bs-target="#landing-pages" type="button" role="tab" aria-controls="landing-pages" aria-selected="true">Landing Pages</Nav.Link>
                                        <Nav.Link className="py-3" eventKey="second" data-bs-toggle="tab" data-bs-target="#header-1" type="button" role="tab" aria-controls="header-1" aria-selected="false">Headline 1</Nav.Link>
                                        <Nav.Link className="py-3" eventKey="three" data-bs-toggle="tab" data-bs-target="#header-2" type="button" role="tab" aria-controls="header-2" aria-selected="false">Headline 2</Nav.Link>
                                    </Nav>
                                </div>
                                <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                                    <Tab.Pane eventKey="first" id="landin-pages" role="tabpanel" aria-labelledby="lainding-pages">
                                        <div className="table-responsive">
                                            <table id="basic-table" className="table mb-0 table-striped" role="grid">
                                                <tbody>
                                                {landingPages.data.landingPages.map((page, index) => (
                                                    <tr key={page.id}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <h6 className="mb-0">{index + 1}</h6>
                                                            </div>
                                                        </td>
                                                        <td className="text-primary">{page.title}</td>
                                                        <td className="text-end">
                                                            <div className="btn btn-sm btn-success" onClick={() => onClickViewButton(page.domain)}>View</div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                {emptyLines().map((number, index) => (
                                                    <tr key={'empty-' + index} style={{ height: '62px'}}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <h6 className="mb-0">{number}</h6>
                                                            </div>
                                                        </td>
                                                        <td className="text-primary"></td>
                                                        <td className="text-end"></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second" id="header-1" role="tabpanel" aria-labelledby="header-1">
                                        <HeaderTable type={'pageHeading'} headers={headlines()} landingPages={landingPages} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="three" id="header-2" role="tabpanel" aria-labelledby="header-2">
                                        <HeaderTable type={'pageSubHeading'} headers={subheadlines()} landingPages={landingPages} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        )}
                    </Card.Body>
                )}
            </Card>
            <Notification/>
        </Fragment>
    )
}

export default ExtractionReport