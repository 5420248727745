import React, { Fragment, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './components/Sidebar'
import Header from './components/Header'
import Footer from './components/Footer'

const AppLayout = () => {
    return (
        <Fragment>
            <Sidebar appName={process.env.REACT_APP_NAME} />
            <main className="main-content">
                <div className="position-relative">
                    <Header />
                </div>
                <div className="container-fluid content-inner pb-0">
                    <Suspense fallback={<div className="react-load"></div>}>
                        <Outlet />
                    </Suspense>
                </div>
                <Footer />
            </main>
        </Fragment>
    )
}

export default AppLayout